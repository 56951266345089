
import { Options, Prop, Vue } from "vue-property-decorator";
import { IBroadcast } from "@/interfaces/IBroadcast";
import { PropType } from "vue";
import Message from "@/components/Message.vue";
import { DEFAULT_DATE_FORMAT } from "@/config/Formats";

@Options({
  components: { Message },
})
export default class NewsItem extends Vue {
  public name = "NewsItem";

  private dateFormat = DEFAULT_DATE_FORMAT;

  @Prop({ type: Object as PropType<IBroadcast> })
  public item!: IBroadcast;

  public get isSeen() {
    return this.item?.seen === false;
  }

  public get subject() {
    return this.item?.subject;
  }

  public get body() {
    return this.item?.body;
  }

  private showPreview(item: any): void {
    this.$emit("on-preview-click", item);
  }
}
