
import { Options, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import NewsHero from "@/components/Pages/News/NewsHero.vue";
import NewsList from "@/components/Pages/News/NewsList.vue";
import { namespace } from "s-vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IBroadcast } from "@/interfaces/IBroadcast";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

const BroadcastModule = namespace("broadcast");

@Options({
  components: {
    ForwardHeader,
    NewsList,
    NewsHero,
    DefaultLayout,
  },
})
export default class News extends Vue {
  public name = "News";

  @BroadcastModule.Action("markAsSeen/update")
  public markAsSeen!: (opt: ICrudOptions) => Promise<void>;

  @BroadcastModule.Getter("list/getDataList")
  public broadcasts!: Array<IBroadcast>;

  @BroadcastModule.Getter("list/getIsLoading")
  public getBroadcastsIsLoading!: boolean;

  @BroadcastModule.Getter("list/getSuccess")
  public markAsSeenSuccess!: boolean;

  @BroadcastModule.Action("list/findAll")
  public findAllBroadcasts!: (option: ICrudOptions) => Promise<void>;

  private mountedLoading = false;

  public async getNews(): Promise<void> {
    await this.findAllBroadcasts({
      resource: "/students/current/news",
      descriptionField: "",
    });
    this.mountedLoading = false;
  }

  public mounted() {
    this.mountedLoading = true;
    this.getNews();
  }

  public get broadcastsIsLoading() {
    return this.mountedLoading && this.getBroadcastsIsLoading;
  }
}
